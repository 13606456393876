import React from 'react';

export default ({ children }) => (
  <div>
    <div className="container col-lg-10">
      {children}
    </div>
    <hr/>
    <footer>

    </footer>
  </div>
);